<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="addContract" aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.add_contract}}</span>
                        </div>
                        <div @click="hide" id="hideContract" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.contract_date}}</label>
                        <b-form-input class="inborder"
                            v-model="contract_date"
                            required
                            type="date"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.contract_expire_date}}</label>
                        <b-form-input class="inborder"
                            v-model="expire_date"
                            required
                            type="date"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.contract_start_date}}</label>
                        <b-form-input class="inborder"
                            v-model="start_date"
                            required
                            type="date"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.mobile}}</label>
                        <b-form-input class="inborder"
                            v-model="mobile"
                            @change="getCustomer()"
                            value=""
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="8" sm="12">
                        <label>{{lang.company_name}}</label>
                        <b-form-input class="inborder"
                            v-model="company_name"
                            value=""
                            readonly
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="backBlack text-center">{{ lang.item_code }}</th>
                                    <th class="backBlack text-center">{{ lang.item_name }}</th>
                                    <th class="backBlack text-center">{{ lang.item_price }}</th>
                                    <th class="backBlack text-center">{{ lang.type }}</th>
                                    <th class="backBlack text-center">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in contract_items" :key="index">
                                    <td style="width:100px;">{{ item.item_code }}</td>
                                    <td>{{ item.item_name }}</td>
                                    <td style="width:100px;">{{ item.item_price }}</td>
                                    <td style="width:100px;">{{ item.type_name[`name_ar`] }}</td>
                                    <td style="cursor:pointer;margin-left: 5px;width:80px;background:red;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" style="background:red !important;" @click="deleteItem(index)" v-b-toggle.crm_project_view>{{lang.delete}}</div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_itesm.item_code"
                                        @change="getItem()"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <th>{{ new_itesm.item_name }}</th>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_itesm.item_price"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <th>
                                        {{ new_itesm.type_name[`name_`+lang.langname] }}
                                    </th>
                                    <td style="cursor:pointer;margin-left: 5px;width:80px;background:blue;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" @click="addNewItem()">{{lang.add}}</div>
                                    </td>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="backBlack text-center">{{ lang.plate_number }}</th>
                                    <th class="backBlack text-center">{{ lang.brand_name }}</th>
                                    <th class="backBlack text-center">{{ lang.model }}</th>
                                    <th class="backBlack text-center">{{ lang.car_year }}</th>
                                    <th class="backBlack text-center">{{ lang.car_color }}</th>
                                    <th class="backBlack text-center">{{ lang.vin_number }}</th>
                                    <th class="backBlack text-center">{{ lang.meter_car }}</th>
                                    <th class="backBlack text-center">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in contracts_cars" :key="index">
                                    <td>{{ item.plate_number }}</td>
                                    <td>{{ item.brand }}</td>
                                    <td>{{ item.model }}</td>
                                    <td>{{ item.car_year }}</td>
                                    <td>{{ item.color }}</td>
                                    <td>{{ item.vin }}</td>
                                    <td>{{ item.meter_car }}</td>
                                    <td style="margin-left: 5px;width:80px;background:red;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" style="background:red !important;" @click="deleteCar(index)">{{lang.delete}}</div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_cars.plate_number"
                                        @change="getCar()"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_cars.brand"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_cars.model"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_cars.car_year"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_cars.color"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_cars.vin"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <th>
                                        <b-form-input class="inborder"
                                        v-model="new_cars.meter_car"
                                        value=""
                                        ></b-form-input>
                                    </th>
                                    <td style="margin-left: 5px;width:80px;background:blue;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" @click="addCar()" v-b-toggle.crm_project_view>{{lang.add}}</div>
                                    </td>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                    </v-col>
                    <v-col class="text-center" cols="12" md="6" sm="12" xs="12">
                    <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="backBlack">{{ lang.terms }}</th>
                                    <th class="backBlack" style="width:80px;">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in terms" :key="index">
                                    <td>{{ item }}</td>
                                    <td class="text-center backRed" @click="delTerms(index)" style="cursor:pointer;">
                                        {{ lang.delete }} 
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>
                                        <b-form-input class="inborder" v-model="iterms" />
                                    </th>
                                    <th @click="addTerms()" class="text-center backGreen" style="cursor:pointer;color:#fff;padding:3px !important;margin-top:3px;">
                                        {{ lang.add }}
                                    </th>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                </v-col>
                <v-col class="text-center" cols="12" md="6" sm="12" xs="12">
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th style="padding:2px !important;" colspan="1" class="backBlack text-center">{{ lang.add_attachement }}</th>
                                <th style="padding:2px !important;" colspan="2" class="backBlack text-center">
                                    <input
                                          type="file"
                                          multiple
                                          label="lang.choose_file"
                                          style="width:98%;border:1px solid #ccc;padding:5px;color:red"
                                          ref="attached"
                                          class="inborder"
                                          @change="selectFiles()"
                                          >
                                </th>
                            </tr>
                            <tr>
                                <th style="padding:2px !important;width:120px;" class="backBlack">
                                    {{ lang.file_size }} 
                                </th>
                                <th style="padding:2px !important;" class="backBlack">{{ lang.file_name }}</th>
                                <th style="padding:2px !important;width:75px;" class="backBlack">{{ lang.action }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(attach,index) in attached" :key="index">
                                <td>{{ $RoundNums(parseFloat(parseFloat(attach.size)/1024)/1024,2) }} MB</td>
                                <td>{{ attach.name }}</td>
                                <td><i @click.prevent="attached.splice(index,1)" class="fas fa-trash-alt m-2" style="float:left;"></i></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            
                        </tfoot>
                    </b-table-simple>
                </v-col>
                </v-row>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="AddContract()" variant="success" class="ma-2" style="width:100px;">{{lang.add}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            id: 0,
            contract_date:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            start_date:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            expire_date:  (new Date(new Date(new Date().setFullYear(new Date().getFullYear() + 1)) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            mobile: '',
            company_name: '',
            contract_items: [],
            new_itesm: {
                item_code: '',
                item_name: '',
                item_price: '',
                type: '',
                type_name: []
            },
            contracts_cars: [],
            new_cars: {
                plate_number: '',
                brand: '',
                model: '',
                car_year: '',
                color: '',
                vin: '',
                meter_car: '',
            },
            terms: [],
            iterms: '',
            attached: [],
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        
        responses: function(){
            let t = [];
            for(let i=0;i < this.users.length;i++){
                t.push(
                    {text: this.users[i].full_name, value: this.users[i].id}
                )
            }
            return t;
        },
        taskStatus: function(){
            let t = [];
            for(let i=0;i < this.statuss.length;i++){
                let text = this.lang.langname == 'ar' ? this.statuss[i].name_ar : this.statuss[i].name_en;
                t.push(
                    {text: text, value: this.statuss[i].value}
                )
            }
            return t;
        }
    },
    methods: {
        selectFiles(){
            const files = this.$refs.attached.files;
            this.attached = [...this.attached, ...files];
        },
        addTerms(){
            if(this.iterms == '') return false;
            this.terms.push(this.iterms);
            this.iterms = ''
        },
        delTerms(index){
            this.terms.splice(index,1)
        },
        resetAll(){
            this.id =  0;
            this.contract_date =   (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.start_date =   (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.expire_date =   (new Date(new Date(new Date().setFullYear(new Date().getFullYear() + 1)) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.mobile =  '';
            this.company_name =  '';
            this.contract_items =  [];
            this.new_itesm =  {
                item_code:   '',
                item_name:   '',
                item_price:   '',
                type:   '',
                type_name:   []
            };
            this.contracts_cars = [];
            this.new_cars =  {
                plate_number:   '',
                brand:   '',
                model:   '',
                car_year:   '',
                color:   '',
                vin:   '',
                meter_car:   '',
            };
        },
        deleteCar(index) {
            this.contracts_cars.splice(index,1)
        },
        deleteItem(index) {
            this.contract_items.splice(index,1)
        },
        getCustomer(){
            const post = new FormData();
            post.append("type" , "getCustomerMob");
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[mobile]",this.mobile);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.company_name =  response.data.results.data.company_name
                    this.id =  response.data.results.data.id
                    if(this.id == 0){
                        let message = 'ؤقم الجوال غير موجود - يرجى اضافة العميل قب استخدام العميل كمشتري';
                        this.$snotify.error(message, 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                        return false;
                    }
                }
            })
        },
        getItem(){
            const post = new FormData();
            post.append("type" , "getItemProd");
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[item_code]",this.new_itesm.item_code);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data.item_name != ''){
                    this.new_itesm =  {
                        item_code: response.data.results.data.item_code,
                        item_name: response.data.results.data.item_name,
                        item_price: response.data.results.data.item_price,
                        type: response.data.results.data.type,
                        type_name: response.data.results.data.type_name
                    }
                }else{
                    let message = 'الصنف غير موجود يرجى اضافة الصنف عن طريق قائمة المخزون من ثم اصناف المخزون';
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
            })
        },
        getCar(){
            const post = new FormData();
            post.append("type" , "getCar");
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[plate_number]",this.new_cars.plate_number);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.new_cars =  {
                        plate_number: response.data.results.data.plate_number,
                        brand: response.data.results.data.brand,
                        model: response.data.results.data.model,
                        car_year: response.data.results.data.car_year,
                        color: response.data.results.data.color,
                        vin: response.data.results.data.vin,
                        meter_car: '',
                    }
                }
            })
        },
        addCar(){
            let tr = true;
            Object.keys(this.new_cars).forEach(key => {
                if(key == 'vin' || key == 'meter_car'){
                    //
                }
                else if(this.new_cars[key] == ''){
                    tr = false;
                }
            })
            if(!tr){
                let message = 'يرجى اكمال بيانات السيارة حتى تستطيع المتابعة';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            this.contracts_cars.push(this.new_cars);
            this.new_cars =  {
                plate_number: '',
                brand: '',
                model: '',
                car_year: '',
                color: '',
                vin: '',
                meter_car: '',
            }
        },
        addNewItem(){
            let tr = true;
            Object.keys(this.new_itesm).forEach(key => {
                if(key == 'type' || key == 'type_name'){
                    //
                }
                else if(this.new_itesm[key] == ''){
                    tr = false;
                }
            })
            if(!tr){
                if(this.id == 0){
                    let message = 'يرجى اكمال بيانات الصنف حتى تستطيع المتابعة';
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
            }

            this.contract_items.push(this.new_itesm);
            this.new_itesm =  {
                item_code: '',
                item_name: '',
                item_price: '',
                type: '',
                type_name: []
            }
        },
        getUsers(){
            const post = new FormData();
            post.append("type" , "getUsers");
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crm_id]",this.crm_id);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                // console.log(response.data)
                if(response && response.data && response.data.results){
                    this.users = response.data.results.data.results
                }
            })
        },
        getStatics(){
            const post = new FormData();
            post.append("type" , "getStaticOptions");
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[phrase]",'taskStatus');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                // console.log(response.data)
                if(response && response.data && response.data.results){
                    this.statuss = response.data.results.data
                }
            })
        },
        AddContract(){
            if(this.id == 0){
                let message = 'العميل غير موجود .. يرجى اضافة العميل واضافة القد من جديد';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.contract_items.length == 0){
                let message = 'لا يمكن اضافة عق بدون اصناف';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.contracts_cars.length == 0){
                let message = 'لا يمكن اضافة عق بدون سيارات';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }

            const post = new FormData();
            post.append("type" , "addNewContract");
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[id]",this.id);
            post.append("data[mobile]",this.mobile);
            post.append("data[contract_date]",this.contract_date);
            post.append("data[expire_date]",this.expire_date);
            post.append("data[start_date]",this.start_date);
            for(let i=0;i<this.terms;i++){
                post.append("data[terms][]",this.terms[i]);
            }

            for(let i=0;i<this.attached.length;i++){
                post.append('data[files]['+i+']',this.attached[i]);
            }
            let items = {};
            for(let i = 0;i<this.contract_items.length;i++){
                items = this.contract_items[i];
                Object.keys(items).forEach(key => {
                    post.append('data[items]['+i+']['+key+']',items[key])
                })
            }
            for(let i = 0;i<this.contracts_cars.length;i++){
                items = this.contracts_cars[i];
                Object.keys(items).forEach(key => {
                    post.append('data[cars]['+i+']['+key+']',items[key])
                })
            }
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                let message = 'تمت الاضافة بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getContracts();
                document.getElementById('hideContract').click();
            });
            
            this.message = '';
        }
    },
    created() {
        this.getUsers();
        this.getStatics();
    },
}
</script>